@media (max-width: 768px) {
  .container {
    filter: blur(5.2px);
    position: relative;
    overflow: auto;
    background: #f6f5ff;
    background: linear-gradient(103deg, #f6f5ff 0%, #e2ecf3 25%, #eaf5f7 82%);
    height: 100vh;
    width: 100%; }
  .courtyard-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8rem;
    width: fit-content;
    text-align: center; }
  .courtyard-title {
    color: #73797a;
    font-size: 4rem;
    letter-spacing: 1.75rem;
    margin-right: -1.75rem;
    width: fit-content;
    position: relative;
    bottom: 3rem;
    height: fit-content;
    text-align: center;
    font-family: "Orbitron", sans-serif;
    background: -webkit-radial-gradient(#b0aeae, #b5b3b3, #b4b2b2, #a3a1a1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .courtyard-bcontainer {
    margin-top: 1.8rem; }
  .courtyard-buttons {
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    width: 12rem;
    height: 7rem;
    line-height: 7rem;
    font-size: 1.6rem;
    font-family: Brush Script MT, Brush Script Std, cursive;
    color: #fbfbfb;
    background: linear-gradient(103deg, #002bc9 0%, #224eec 74%, #0061d8 81%, #5479ff 97%);
    border-radius: 0.7rem; } }

@media (min-width: 769px) {
  .container {
    filter: blur(5.2px);
    position: relative;
    overflow: auto;
    background: #f6f5ff;
    background: linear-gradient(103deg, #f6f5ff 0%, #e2ecf3 25%, #eaf5f7 82%);
    height: 100vh;
    width: 100%; }
  .courtyard-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8rem;
    width: fit-content; }
  .courtyard-title {
    color: #73797a;
    font-size: 8rem;
    font-weight: 1;
    letter-spacing: 1.75rem;
    width: fit-content;
    padding-right: 4rem;
    position: relative;
    bottom: 3rem;
    font-family: "Orbitron", sans-serif;
    background: -webkit-radial-gradient(#b0aeae, #b5b3b3, #b4b2b2, #a3a1a1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .courtyard-buttons {
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    width: 12rem;
    height: 7rem;
    line-height: 7rem;
    font-size: 1.6rem;
    font-family: Brush Script MT, Brush Script Std, cursive;
    color: #fbfbfb;
    background: linear-gradient(103deg, #002bc9 0%, #224eec 74%, #0061d8 81%, #5479ff 97%);
    border-radius: 0.7rem; }
  .cover {
    overflow: auto;
    width: 100%;
    height: 100vh;
    background: white; } }

.container {
  position: relative;
  width: 100vw;
  height: 100vh; }

.inlines {
  display: inline-block; }

.inlines_cover {
  display: inline-block; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.vert-text {
  position: relative;
  margin-top: auto;
  margin-bottom: auto; }

@media (max-width: 768px) {
  .music-yard {
    text-align: center;
    overflow: auto;
    font-family: "Nunito", sans-serif;
    background: linear-gradient(160deg, #2908de 25%, #ea0884 65%, #f92828 85%);
    background-attachment: fixed;
    background-size: cover; }
  .altar-base {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    height: fit-content; }
  .altar-selah {
    color: white;
    padding-top: 5.5rem;
    margin-bottom: 4rem;
    font-size: 2.7rem;
    font-family: "Nunito", sans-serif;
    width: 16rem;
    margin-right: auto;
    margin-left: auto; }
  .altar-ethos {
    width: 20rem;
    height: 5rem;
    border: 0.1rem white solid;
    border-radius: 0.3rem;
    color: white;
    margin: 1rem;
    margin-bottom: 1.5rem;
    font-family: "Nunito", sans-serif;
    overflow: auto;
    font-size: 1;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none; }
    .altar-ethos-music {
      line-height: 5rem;
      height: fit-content;
      vertical-align: middle;
      padding-left: 0.2rem;
      padding-right: 0.2rem; }
  .altar-plate {
    text-align: left;
    color: white;
    margin-top: 6rem;
    margin-left: 2rem;
    margin-bottom: 7rem;
    width: fit-content; }
    .altar-plate-sln {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-family: "Pacifico", cursive; }
    .altar-plate-lattice {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; }
    .altar-plate-jotp {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-family: "PT Serif", serif; } }

@media (min-width: 768px) {
  .music-yard {
    background: linear-gradient(160deg, #2908de 25%, #ea0884 65%, #f92828 85%);
    text-align: center;
    overflow: auto;
    font-family: "Nunito", sans-serif; }
  .altar-base {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    height: fit-content; }
  .altar-selah {
    color: white;
    padding-top: 5.5rem;
    margin-bottom: 4rem;
    font-size: 2.7rem;
    font-family: "Nunito", sans-serif;
    width: 16rem;
    margin-right: auto;
    margin-left: auto; }
  .altar-ethos {
    width: 20rem;
    height: 5rem;
    border: 0.1rem white solid;
    border-radius: 0.3rem;
    color: white;
    margin: 1rem;
    margin-bottom: 1.5rem;
    font-family: "Nunito", sans-serif;
    overflow: auto;
    font-size: 1;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none; }
    .altar-ethos-music {
      line-height: 5rem;
      height: fit-content;
      vertical-align: middle;
      padding-left: 0.2rem;
      padding-right: 0.2rem; }
  .altar-plate {
    text-align: left;
    color: white;
    margin-top: 6rem;
    margin-left: 2rem;
    margin-bottom: 7rem;
    width: fit-content; }
    .altar-plate-sln {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-family: "Pacifico", cursive; }
    .altar-plate-lattice {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; }
    .altar-plate-jotp {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-family: "PT Serif", serif; } }
